<template>
  <b-card-code title="Set Effects for current Project">
    <div>
        <b-form class="position-relative" @submit.prevent="onSubmit">
            <b-form-group label="Mirror effect" label-cols-lg="2">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        :disabled="!hasLicensePermission('effects', 'mirror_effect')"
                        class="checkbox"
                        v-model="mirrorEffect"
                    ></b-form-checkbox>
                    <a
                        v-if="!hasLicensePermission('effects', 'mirror_effect')"
                        href="/pricing"
                        target="_blank"
                        title="Mirror effects for higher license"
                    >
                        <feather-icon
                            class="cursor-pointer ml-1"
                            icon="AlertTriangleIcon"
                            size="20"
                            style="color: #F00; margin-bottom: 5px;"
                        />
                    </a>
                </div>
            </b-form-group>

            <div v-if="mirrorEffect" style="margin-bottom: 25px;">
                <b-form-checkbox-group
                    label="Types of mirror effect"
                    label-cols-lg="2"
                    v-model="selectedMirrorEffectTypes"
                    :options="mirrorEffectTypeOptions"
                    :state="mirrorTypesState"
                >
                </b-form-checkbox-group>
            </div>

            <b-form-group label="Tone mapping" label-cols-lg="2">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        :disabled="!hasLicensePermission('tone-mapping', 'tone_mapping')"
                        class="checkbox"
                        v-model="toneMapping"
                    ></b-form-checkbox>
                    <a
                        v-if="!hasLicensePermission('tone-mapping', 'tone_mapping')"
                        href="/pricing"
                        target="_blank"
                        title="Tone mapping effects for higher license"
                    >
                        <feather-icon
                            class="cursor-pointer ml-1"
                            icon="AlertTriangleIcon"
                            size="20"
                            style="color: #F00; margin-bottom: 5px;"
                        />
                    </a>
                </div>
            </b-form-group>
            <b-row v-if="toneMapping">
                <b-col lg="1">
                    <label for="textarea" style="font-size: 1rem">Tone maping code</label>
                </b-col>
                <b-col lg="6">
                    <b-form-textarea
                        id="textarea"
                        v-model="toneMappingCode"
                        placeholder="Leave the field empty for the default value"
                        rows="3"
                        max-rows="8"
                    ></b-form-textarea>
                </b-col>
            </b-row>

            <h5 class="mb-2">Custom effects:</h5>
            <b-card-code
                v-for="editorEffect in filteredEditorEffects"
                :key="editorEffect.id"
                class="effect-field"
                border-variant="dark"
                bg-variant="light"
            >
                <b-form-select v-model="editorEffect.effect_type" :options="editorEffectOptions" class="select-effect">
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select an effect type --</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-group
                    :label="`Has custom ${editorEffect.effect_type == 1 ? 'diamond' : 'metallic'} HDR file${editorEffect.effect_typ == 1 ? '' : 's'}`"
                    label-cols-lg="2"
                >
                    <div class="d-flex align-items-center">
                        <b-form-checkbox
                            class="checkbox"
                            v-model="editorEffect.has_custom_hdr"
                        ></b-form-checkbox>
                    </div>
                </b-form-group>
                <div v-if="editorEffect.has_custom_hdr">
                    <b-form-radio-group
                        v-model="editorEffect.hdr_loader_type"
                        :options="{1: 'RGBELoader', 2: 'HDRCubeTextureLoader'}"
                        class="mb-1"
                    />
                    <b-form-group
                        v-for="index in getHdrCount(editorEffect.effect_type, editorEffect.hdr_loader_type)"
                        :key="index"
                        class="hdr-form"
                        :class="{
                            'hdr-success': isHdrIndexInNumbers(index, editorEffect.hdrNumbers),
                            'hdr-warning': !isHdrIndexInNumbers(index, editorEffect.hdrNumbers)
                        }"
                    >
                        <b-form-file
                            accept=".hdr"
                            ref="file-input"
                            :placeholder="isHdrIndexInNumbers(index, editorEffect.hdrNumbers) ? 'Has HDR file in remote'  : 'No HDR file chosen'"
                            v-model="editorEffect.customHDRFiles[index]"
                            @change="hdrFileChanged(index, editorEffect.hdrNumbers)"
                        />
                    </b-form-group>
                </div>
                <div class="description" v-if="showDescription && descriptionEffectID === editorEffect.id" v-click-outside="hideDescription">
                    {{editorEffect.id}}
                    <h5>Objects list example:</h5>
                    <p v-if="editorEffect.effect_type === 1">[{"name": "DIAMOND_1", "color": "#ffffff"}, {"name": "DIAMOND_2", "color": "#00ffff"}]</p>
                    <p v-else>[{"name": "METAL_1"}, {"name": "METAL_2"}]</p>
                </div>
                <div class="description-rules" v-if="showRulesDescription" v-click-outside="hideRulesDescription">
                    <h5>Warning</h5>
                    <ol class="description-list">
                        <li>If there are more than one meshes or materials with specified name, the effect will be applied only to the first object with that name</li>
                        <li>Specify Meshes names for Diamond or Metallic effects</li>
                        <li>Metallic effect will be applied to specified Mesh's material only</li>
                    </ol>
                </div>
                <b-row v-if="editorEffect.effect_type == 1 || editorEffect.effect_type == 2" class="align-items-center">
                    <b-col lg="1">
                        <label style="font-size: 1rem">List of objects with diamond effect by <a style="color: #2666F3" @click="setShowDescription(editorEffect.id)">example</a></label>
                        <label style="font-size: 1rem; margin-top: 20px">Also follow some <a style="color: #2666F3" @click="showRulesDescription = true">rules</a> please</label>
                    </b-col>
                    <b-col lg="6">
                        <b-form-textarea
                            @keydown="preventTab"
                            id="diamond-object"
                            v-model="editorEffect.meshes_list_json"
                            placeholder='[{"name": "DIAMOND_1", "color": "#ffffff"}, {"name": "DIAMOND_2", "color": "#00ffff"}]'
                            rows="15"
                            max-rows="16"
                            no-resize
                        ></b-form-textarea>
                    </b-col>
                </b-row>
                <b-button variant="outline-danger" @click="deleteCustomEffect(editorEffect.id)" class="mt-2">Delete</b-button>
            </b-card-code>
            <b-button variant="outline-primary" @click="addCustomEffect">Add custom effect</b-button>
            <div class="d-flex justify-content-center mt-2">
                <b-button
                    ref="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="!mirrorTypesState || busyButton"
                >
                    Submit
                </b-button>
            </div>

            <div v-if="busy" no-wrap @shown="onShown" @hidden="onHidden">
                <div class="busy-background">
                    <div
                        v-if="processing"
                        class="text-center p-3 bg-primary text-light rounded processing"
                    >
                    <feather-icon icon="UploadCloudIcon" size="20" />
                    <div class="mb-2">Preservation...</div>
                    <b-progress
                        min="1"
                        max="5"
                        :value="counter"
                        variant="success"
                        height="6px"
                        class="mx-n3"
                    />
                    </div>
                    <div
                        v-else
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3 confirmate-buttons"
                    >
                        <b-card-text class="font-weight-bolder confirmation-text">
                            Are you sure?
                        </b-card-text>
                        <div class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mr-3"
                                @click="onCancel"
                            >
                                Cancel
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="outline-success"
                                @click="onOK"
                            >
                                OK
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code"
import { 
    BButton,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCardText,
    BFormCheckbox,
}
from "bootstrap-vue"
import { ref } from "@vue/composition-api"
import store from "@/store"
import Ripple from "vue-ripple-directive";
import { errorMessage } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BButton,
        BOverlay,
        BForm,
        BProgress,
        BFormGroup,
        BInputGroup,
        BCardCode,
        BFormInput,
        BCardText,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    computed: {
        settings() {
            return this.$store.state.Editor.cameraSetting
        },
        editor() {
            return this.$store.state.Editor.editor
        },
        mirrorTypesState() {
            return !this.mirrorEffect || this.selectedMirrorEffectTypes.length >= 1
        },
        licensePermissions() {
            return this.$store.getters["app/LICENSE_PERMISSIONS"]
        },
        diamondObjectIsOk() {
            return this.diamondObjectValue != '' && !(this.diamondObjectsList.includes(this.diamondObjectValue))
        },
        filteredEditorEffects() {
            return this.$store.getters["Effects/FILTERED_EDITOR_EFFECTS"]
        },
        editorEffects() {
            return this.$store.getters["Effects/EDITOR_EFFECTS"]
        },
    },
    data() {
        return {
            mirrorEffect: false,
            toneMapping: false,
            toneMappingCode: {},
            selectedMirrorEffectTypes: ['vertical'],
            mirrorEffectTypeOptions: [
                { text: 'Vertical mirror', value: 'vertical'},
                { text: 'Horizontal mirror', value: 'horizontal'},
            ],
            busy: false,
            busyButton: true,
            processing: false,
            counter: 1,
            interval: null,
            editorIsLoaded: false,
            showDescription: false,
            descriptionEffectID: null,
            showRulesDescription: false,
            editorEffectOptions: [
                {value: 1, text: 'Diamond'},
                {value: 2, text: 'Metallic'},
            ],
            deletedEffects: [],
        }
    },
    methods: {
        submitEffects() {
            this.editorEffects.forEach(effect => {
                try {
                    if (typeof(effect.meshes_list_json) == 'string' && effect.deleted !== true) {
                        JSON.parse(effect.meshes_list_json) // Check meshes list format
                        effect.meshes_list_json_stringified = effect.meshes_list_json
                    } else {
                        // List format is not allowed for post/patch
                        effect.meshes_list_json_stringified = JSON.stringify(effect.meshes_list_json)
                    }

                    if (effect.deleted) {
                        this.$store.dispatch('Effect/DELETE_EFFECT', effect.id).then(res => {
                            if (res.status === 204) this.$store.commit('Effects/REMOVE_EDITOR_EFFECT', effect.id)
                        })
                    } else if (effect.localOnly) {
                        this.$store.dispatch('Effect/POST_EFFECT', effect).then(res => {
                            if (res.status == 201) {
                                effect.localOnly = false // Now effect in database
                                effect.id = res.data.id
                            }
                        })
                    } else {
                        this.$store.dispatch('Effect/PATCH_EFFECT', effect)
                    }
                } catch {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Wrong effects list format!`,
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus();
        },
        onHidden() {
            // In this case, we return focus to the submit button
            // You may need to alter this based on your application requirements
            this.$refs.submit.focus();
        },
        hideDescription() {
            this.showDescription = false
        },
        hideRulesDescription() {
            this.showRulesDescription = false
        },
        setToneMap() {
            if (!this.toneMappingCode) {
                this.toneMappingCode = `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
                    float toneMappingWhitePoint = 0.0;
                    float contrast(float mValue, float mScale, float mMidPoint) {return clamp( (mValue - mMidPoint) * mScale + mMidPoint, 0.0, 1.0);}
                    float contrast(float mValue, float mScale) {return contrast(mValue,  mScale, .5);}
                    vec3 contrast(vec3 mValue, float mScale, float mMidPoint) {return vec3( contrast(mValue.r, mScale, mMidPoint), contrast(mValue.g, mScale, mMidPoint), contrast(mValue.b, mScale, mMidPoint) );}
                    vec3 contrast(vec3 mValue, float mScale) {return contrast(mValue, mScale, .5);}
                    vec3 CustomToneMapping( vec3 color ) {
                        vec3 memcolor = color;
                        vec3 linear = toneMappingExposure * color;
                        const mat3 ACESInputMat = mat3(vec3( 0.59719, 0.07600, 0.02840 ),vec3( 0.35458, 0.90834, 0.13383 ),vec3( 0.04823, 0.01566, 0.83777 ));
                        const mat3 ACESOutputMat = mat3(vec3(  1.60475, -0.10208, -0.00327 ),vec3( -0.53108,  1.10813, -0.07276 ),vec3( -0.07367, -0.00605,  1.07602 ));
                        color *= toneMappingExposure / 0.5;
                        color = ACESInputMat * color;
                        // Apply RRT and ODT
                        color = RRTAndODTFit( color );
                        color = ACESOutputMat * color;
                        vec3 film =  color ;
                        float gray = dot(color.rgb, vec3(0.299, 0.587, 0.114));
                        gray = clamp(gray,0.0,1.0);
                        return (film+linear)*1.2;
                    }`
            }
        },
        hasLicensePermission(permission_type, permission_field) {
            // Look in license_permission.json to manage permission_type and permission_field
            try {
                return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
            } catch {
                return true
            }
        },
        isLicenseChosen(licenseList) {
            for (let license of licenseList) {
                if (license == this.projectData.project_license_type) {
                    return true
                }
            }
            return false
        },
        onSubmit() {
            if (this.editorIsLoaded) {
                this.processing = false
                this.busy = true
                this.busyButton = true
            }
        },
        onCancel() {
            this.busy = false
            this.busyButton = false
        },
        updateProcessing() {
            // Counter is going up from 1 to 5
            // Simulate an async request
            this.clearInterval();
            this.interval = setInterval(() => {
                if (this.counter < 5) {
                this.counter += 1
                } else {
                this.clearInterval()
                this.$nextTick(() => {
                    // eslint-disable-next-line
                    this.busy = this.processing = false
                });
                }
            }, 350);
        },
        async sendRequestUpdateHDRDiamondAsync() {
            await this.$store.dispatch("Editor/updateHDRFile", {
                file_HDR_Diamond: this.customDiamondHdrFile,
            })
            .catch((error) => {
                errorMessage(this.$toast, error)
            });
        },

        async sendRequestRemoveHDRDiamondtAsync() {
            await this.$store.dispatch("Editor/removeHDRFile", 'dmnd')
            .catch((error) => {
                errorMessage(this.$toast, error)
            });
        },
        async onOK() {
            this.processing = true
            this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
            this.counter = 1
            const bgSettings = this.settings
            
            bgSettings.effects = {
                mirrorEffect: this.mirrorEffect,
                mirrorEffectTypes: this.selectedMirrorEffectTypes,
                toneMapping: this.toneMapping,
                toneMappingCode: this.toneMapping ? this.toneMappingCode : '',
            }
            
            await this.setEffectsSettings(bgSettings)
            this.updateProcessing()
            this.$store.commit('app/SET_IS_PROJECT_UPDATING', false)
            await this.loadEditorAsync()

            this.submitEffects()
        },
        async setEffectsSettings(bgSettings) {
            // PATCH Editor with new settings_json file
            await this.$store.commit("Editor/SET_CAMERA", bgSettings)
            await this.$store
                .dispatch("Editor/changeSetting", this.$store.state.Editor.editor.id)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: "You have successfully updated Project effects settings!",
                            icon: "EditIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((error) => {
                    errorMessage(this.$toast, error)
                })
        },
        async loadEditorAsync() {
            const _this = this
            // Set headers for fetch
            let myHeaders = new Headers()
            myHeaders.append('pragma', 'no-cache')
            myHeaders.append('cache-control', 'no-cache')

            // Set request options for fetch
            const myInit = {
                method: 'GET',
                headers: myHeaders,
            };

            await this.$store
                .dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
                .then((res) => {
                this.settings_json_s3_path = res.data.settings_json_s3_path
                
                this.$store.commit("Editor/SET_EDITOR", res.data)
                this.$store
                .dispatch("Editor/setCameraSetting") // Fetch settings json file
                .then((response) => response.json())
                .then((data) => {
                    this.$store.commit("Editor/SET_CAMERA", data)
                });
            })
                .catch((error) => {
                errorMessage(this.$toast, error)
                });

            // Get data by settings_json_s3_path PATH and myInit request options
            await fetch(this.settings_json_s3_path, myInit)
                .then(function (response) {
                return response.json() // Get json data first
                }).then(function(response) {
                if (response.effects) {
                    _this.mirrorEffect = response.effects.mirrorEffect? response.effects.mirrorEffect : false
                    _this.selectedMirrorEffectTypes = response.effects.mirrorEffectTypes? response.effects.mirrorEffectTypes : ['vertical']
                    _this.toneMapping = response.effects.toneMapping
                    _this.toneMappingCode = response.effects.toneMappingCode
                }
                _this.editorIsLoaded = true
                _this.busyButton = false
            })
            this.setToneMap()
        },
        clearInterval() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
        preventTab(event) {
            if (event.key == 'Tab') {
                event.preventDefault()
            }
        },
        addCustomEffect() {
            this.$store.commit('Effects/ADD_EDITOR_EFFECT', {
                id: 1,
                effect_type: null,
                meshes_list_json: '[]',
                has_custom_hdr: false,
                hdr_loader_type: 1,
                editor: this.$store.state.Editor.editor.id,
                localOnly: true,
                customHDRFiles: [],
                hdrNumbers: [],
            })
        },
        deleteCustomEffect(effectID) {
            const effect = this.editorEffects.find(effect => effect.id == effectID)

            if (effect.localOnly) {
                // Just created Effects should be deleted from local store only
                this.$store.commit('Effects/REMOVE_EDITOR_EFFECT', effectID)
            } else {
                // Delete status allows to delete Effect from database
                this.$store.commit('Effects/ADD_DELETE_STATUS_FOR_EFFECT', effectID)
            }
        },
        getHdrCount(effectType, hdrLoaderType) {
            // One hdr field for Diamond type or if hdr loader is RGBELoader
            return Number(effectType) === 1 || Number(hdrLoaderType) === 1 ? [0] : [0, 1, 2, 3, 4, 5]
        },
        isHdrIndexInNumbers(index, numbers) {
            return numbers && numbers.some(num => num === index)
        },
        hdrFileChanged(numIndex, numbers) {
            if (!numbers.some(num => num == numIndex)) numbers.push(numIndex)
        },
        setShowDescription(effectID) {
            this.showDescription = true
            this.descriptionEffectID = effectID
        },
    },
    setup() {
        const projectData = ref(null)

        store
        .dispatch("Project/loadProject", store.state.Project.project.id)
        .then((response) => {
            projectData.value = response.data;
        })
        .catch((error) => {
            if (error.response.status === 404) {
            projectData.value = undefined;
            }
        });

        return {
            projectData,
        };
    },
    mounted() {
        this.loadEditorAsync()
        store.dispatch('Effects/LOAD_EFFECTS_BY_EDITOR', store.state.Editor.editor.id)
    },
    beforeDestroy() {
        this.clearInterval();
    },
}
</script>

<style lang="scss">
.select-effect {
    width: 30%;
    margin-bottom: 10px;
}
.hdr-success {
    label {
        border-color: rgb(143, 238, 143);
    }
}
.hdr-warning {
    label {
        border-color: rgb(209, 109, 109);
    }
}
.hdr-form {
    min-width: 205px;
    max-width: 30%;
}
.busy-background {
  background-color: #80808031;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
.confirmate-buttons {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
}
.processing {
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
}
.confirmation-text {
  color: rgb(48, 44, 44);
}
.code-toggler {
  display: none;
}
.custom-checkbox, .checkbox {
    margin-top: 6px;
    input, label {
        cursor: pointer;
    }
}
.custom-radio {
    input, label {
        cursor: pointer;
    }
}
.diamond-object-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;

    p {
        border: 2px solid rgba(0, 0, 0, 0.476);
        background-color: rgba(146, 144, 144, 0.092);
        border-radius: 14px;
        cursor: pointer;
        padding: 5px;
        margin: 0;
    }
}
.description, .description-rules {
    position: fixed;
    z-index: 11;
    width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(248, 248, 248, 0.8);
    color: black;
    border: 1px solid rgba(40, 39, 39, 0.8);
    border-radius: 10px;
    padding: 20px;
    bottom: 45%;
    
    h5 {
        color: #5e5873;
    }
}
.description-rules {
    ol.description-list {
        margin-bottom: 0px;
        li {
            margin-bottom: 10px;
        }
    }

}
</style>
